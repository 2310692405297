@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .frosty {
        @apply relative bg-frosted-gradient before:absolute top-0 left-0 before:block before:opacity-50 before:w-full before:h-full before:content-[""] before:bg-frosted-image before:-z-10 before:top-0 before:left-0;
    }
}


@layer base {
    @font-face {
        font-family: 'GothamBook';
        font-style: normal;
        src: local('GothamBook'), url('/assets/fonts/Gotham-Book.woff') format('woff'), url('/assets/fonts/Gotham-Book.ttf') format('truetype');
    }

    @font-face {
        font-family: 'Gotham';
        font-style: normal;
        src: local('Gotham'), url('/assets/fonts/Gotham-Medium.woff') format('woff'), url('/assets/fonts/Gotham-Medium.ttf') format('truetype');
    }

    @font-face {
        font-family: 'GothamBold';
        font-style: normal;
        src: local('GothamBold'), url('/assets/fonts/Gotham-Bold.woff') format('woff'), url('/assets/fonts/Gotham-Bold.ttf') format('truetype');
    }

    * {
        font-family: "Gotham", system-ui, sans-serif;
    }
}

@layer utilities {
    .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: #ACACAC;
        border-radius: 9999px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background-color: #e5e7eb;
    }
}